// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';

import Loader from './Loader';
import { isLoggedIn } from '../lib/auth';

interface PrivateRouteProps extends RouteComponentProps {
  component: React.ComponentType;
}

const PrivateRoute = ({ component: Component }: PrivateRouteProps): React.ReactElement => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        setLoading(false);
      } else {
        navigate('/login');
      }
    });
  }, []);

  if (loading) {
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} style={{ paddingTop: '64px' }}>
          <Loader />
        </Grid>
      </Grid>
    );
  }

  return <Component />;
};
export default PrivateRoute;

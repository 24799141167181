/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-console */
import * as React from 'react';
import { Link, navigate } from 'gatsby';
import axios from 'axios';
import qs from 'qs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import Title from './Title';
import SEO from './Seo';
import Loader from './Loader';
import CategoryRadioGroup from './CategoryRadioGroup';

const AddPage = (): React.ReactElement => {
  const [loading, setLoading] = React.useState(false);
  const [amount, setAmount] = React.useState('');
  const [category, setCategory] = React.useState('food');
  const [otherCategory, setOtherCategory] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [error, setError] = React.useState(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (Number(amount) < 0) {
      setError("Can't submit negative amount");
    } else {
      setError(null);
      setLoading(true);

      const formValues = {
        amount,
        category: category === 'other' ? otherCategory : category,
        comment,
      };

      axios
        .post('/api/expense', qs.stringify(formValues))
        .then(() => {
          navigate('/success', {
            state: formValues,
          });
        })
        .catch((e) => {
          if (e?.response) {
            if (e.response.status === 401) {
              setError('Sorry, your email or password is incorrect. Please login again');
            } else {
              setError(e.response.data || 'Something went wrong and there is no data specified');
            }
            console.error('e.response.status: ', e.response.status);
            console.error('e.response: ', e.response);
          } else if (e?.request) {
            setError(
              e.request.responseText ||
                e.request.statusText ||
                'Something went wrong with the network call. This is not backend fault',
            );

            console.error('e.request: ', e.request);
          } else {
            setError(e.message);

            console.error('e.message: ', e.message);
          }

          setLoading(false);
        });
    }
  };

  const handleErrorClose = (): void => {
    setError(null);
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={4} direction="column" alignItems="center">
        <SEO title="Add" />

        <Grid item xs={12}>
          <Title />
        </Grid>

        <Grid container item xs={12} sm={6} md={5}>
          <FormControl required fullWidth variant="filled">
            <InputLabel htmlFor="amount-input">Amount</InputLabel>
            <FilledInput
              id="amount-input"
              type="number"
              value={amount}
              disabled={loading}
              data-lpignore="true"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setAmount(event.target.value);
              }}
            />
          </FormControl>
        </Grid>

        <Grid container item xs={12} sm={6} md={5}>
          <CategoryRadioGroup
            disabled={loading}
            value={category}
            onChange={setCategory}
            otherCategory={otherCategory}
            onOtherCategoryChange={setOtherCategory}
          />
        </Grid>

        <Grid container item xs={12} sm={6} md={5}>
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="comment-input">Comment</InputLabel>
            <FilledInput
              id="comment-input"
              type="text"
              value={comment}
              data-lpignore="true"
              disabled={loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setComment(event.target.value);
              }}
            />
          </FormControl>
        </Grid>

        <Grid container item xs={12} sm={6} md={5}>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            {loading ? <Loader /> : 'Submit'}
          </Button>
        </Grid>

        <Grid container item xs={12} sm={6} md={5}>
          <Button disabled={loading} color="primary" size="large" fullWidth component={Link} to="/">
            Cancel
          </Button>
        </Grid>

        <Snackbar open={!!error}>
          <Alert onClose={handleErrorClose} elevation={6} variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Grid>
    </form>
  );
};
export default AddPage;

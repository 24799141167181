import * as React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';

interface CategoryRadioGroupProps {
  value: string;
  onChange: (key: string) => void;
  otherCategory: string;
  onOtherCategoryChange: (key: string) => void;
  disabled: boolean;
}

const CategoryRadioGroup = ({
  value,
  onChange,
  otherCategory,
  onOtherCategoryChange,
  disabled,
}: CategoryRadioGroupProps): React.ReactElement => {
  const categories = [
    'Food',
    'Lunch',
    'Alcohol',
    'Eating outside',
    'Car',
    'Commute',
    'Personal',
    'Gaming',
    'Tech',
    'Apartment/communals',
    'Gifts',
    'Entertainment',
    'Vacation',
    'Other',
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">Category</FormLabel>
        <RadioGroup aria-label="category" value={value} onChange={handleChange}>
          {categories.map((category) => (
            <FormControlLabel
              disabled={disabled}
              key={category}
              value={category.toLowerCase()}
              control={<Radio />}
              label={category}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {value === 'other' && (
        <FormControl required fullWidth variant="filled">
          <InputLabel htmlFor="other-category-input">Other category</InputLabel>
          <FilledInput
            id="other-category-input"
            type="text"
            value={otherCategory}
            data-lpignore="true"
            disabled={disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              onOtherCategoryChange(event.target.value);
            }}
          />
        </FormControl>
      )}
    </>
  );
};

export default CategoryRadioGroup;

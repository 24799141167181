import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Router } from '@reach/router';
import AddPage from '../components/AddPage';
import PrivateRoute from '../components/PrivateRoute';

const Add = (): React.ReactNode => (
  <Router>
    <PrivateRoute path="/add" component={AddPage} />
  </Router>
);
export default Add;
